import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

class BlogGrid extends Component {
  state = { news: [], isLoaded: false, numPages: 0 };

  componentDidMount() {
    axios
      .get(process.env.PUBLIC_URL + "/news.json")
      .then((res) => {
        this.setState({
          news: res.data,
          isLoaded: true,
          numPages: 1,
        });
        console.log(this.state);
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { news, isLoaded, numPages } = this.state;

    function recHasProp(obj, prop) {
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (p === prop) {
            return obj;
          } else if (obj[p] instanceof Object && recHasProp(obj[p], prop)) {
            return obj[p];
          }
        }
      }
      return null;
    }

    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    if (isLoaded) {
      return (
        <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
          <div className="container">
            <div className="row">
              {/* Blog Item */}
              {news.map((newsitem) => (
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="ltn__blog-item ltn__blog-item-3">
                    <div className="ltn__blog-img">
                      <Link to={"/news-detail/" + newsitem.id}>
                        <img
                          src={"/assets/img/news/" + newsitem.image}
                          alt="#"
                        />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-author go-top">
                            <Link to={"/news-detail/" + newsitem.id}>
                              <i className="far fa-user" />
                              {newsitem.author}
                            </Link>
                          </li>
                          <li className="ltn__blog-tags go-top">
                            <Link to={"/news-detail/" + newsitem.id}>
                              <i className="fas fa-tags" />
                              Hasting's Freight News
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="ltn__blog-title">
                        <Link to={"/news-detail/" + newsitem.id}>
                          {newsitem.title}
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-meta">
                          <ul>
                            <li className="ltn__blog-date">
                              <i className="far fa-calendar-alt" />
                              {newsitem.printdate} Bob
                            </li>
                          </ul>
                        </div>
                        <div className="ltn__blog-btn">
                          <Link to={"/news-detail/" + newsitem.id}>
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
        <div className="container">
          <div className="row">
            <center>
              <img
                src={publicUrl + "assets/img/icons/icon-img/loading.gif"}
                alt="#"
              ></img>
              <h3>Loading...</h3>;
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogGrid;
