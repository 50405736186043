import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutUsYourCareerContent extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__team-details-member-info text-center mb-40">
                <div className="team-details-img">
                  <img
                    src={publicUrl + "assets/img/others/opportunities.jpg"}
                    alt="Team Member Image"
                  />
                </div>
                <h2>Hastings Freight Ltd</h2>
                <h6 className="text-uppercase ltn__secondary-color">
                  Sheffield, United Kingdom
                </h6>
                {/* <div className="ltn__social-media-3">
                  <ul>
                    <li>
                      <a href="#" title="Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Linkedin">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> 
                </div>*/}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="ltn__team-details-member-info-details">
                <h3>What can we offer</h3>
                <p>Your career starts with us!</p>
                <p>
                  We are a busy family run business that believes in leaving a
                  lasting, positive impact on our environment, community, and
                  customers.
                </p>
                <p>
                  If you're passionate about making a difference and working as
                  part of a team, we're looking for you!
                </p>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    Want to find out more what it's like to work at Hastings
                    Freight?{" "}
                    <Link to="/careers" className="highlightLink">
                      Click Here
                    </Link>{" "}
                    to see what our team says.
                  </p>
                </div>
                <h3>Current Vacancies</h3>
                <p>
                  We're always looking for new, enthusiastic people to join our
                  work force. If this sounds like you then send us a copy of
                  your CV to hr@hastingsfreight.co.uk
                </p>
                <p>
                  We do sometimes have specific roles we are actively recruiting
                  for and these will appear below when we're looking to fill a
                  specific gap in our team so keep checking back
                </p>
              </div>
              <div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
                {/* ltn__product-item */}
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                    <div className="product-img go-top">
                      <img
                        src={publicUrl + "assets/img/team/hiring.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="product-info">
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badg">APPRENTICESHIP - FULL TIME</li>
                        </ul>
                      </div>
                      <h2 className="product-title go-top">
                      <a href="https://www.findapprenticeship.service.gov.uk/apprenticeship/1000251079" target="_blank" className="highlightLink">
                        Transport Compliance Apprentice
                        </a>
                      </h2>
                      <div className="product-img-location">
                        <ul>
                          <li>
                            <i className="flaticon-pin" /> Hastings Freight Head
                            Office, Sheffield
                          </li>
                        </ul>
                      </div>
                      <br />
                      <p>
                      We're recruiting for a Transport Compliance Apprentice to work in our operation. If you think this is you, please apply
                      {" "}
                      <a href="https://www.findapprenticeship.service.gov.uk/apprenticeship/1000251079" target="_blank" className="highlightLink">
                      HERE
                    </a>{" "}
                      </p>
                    </div>
                    <div className="product-info-bottom">
                      <div className="product-price">
                        <span>
                        £15,600.00 <label> annual wage</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ltn__product-item */}
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                    <div className="product-img go-top">
                      <img
                        src={publicUrl + "assets/img/team/hiring.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="product-info">
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badg">APPRENTICESHIP - FULL TIME</li>
                        </ul>
                      </div>
                      <h2 className="product-title go-top">
                      {" "}
                    <a href="https://www.findapprenticeship.service.gov.uk/apprenticeship/1000251094" target="_blank" className="highlightLink">
                    Business Administration Apprentice
                    </a>{" "}
                      
                      </h2>
                      <div className="product-img-location">
                        <ul>
                          <li>
                            <i className="flaticon-pin" /> Hastings Freight Head
                            Office, Sheffield
                          </li>
                        </ul>
                      </div>
                      <br />
                      <p>
                      We're recruiting for a Business Administration Apprentice to work in our operation. If you think this is you, please apply
                      {" "}
                      <a href="https://www.findapprenticeship.service.gov.uk/apprenticeship/1000251094" target="_blank" className="highlightLink">
                      HERE
                    </a>{" "}
                      </p>
                    </div>
                    <div className="product-info-bottom">
                      <div className="product-price">
                        <span>
                        £14,560.00 <label> annual wage</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
                {/* ltn__product-item */}
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                    <div className="product-img go-top">
                      <img
                        src={publicUrl + "assets/img/team/hiring.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="product-info">
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badg">FULL TIME</li>
                        </ul>
                      </div>
                      <h2 className="product-title go-top">
                        HGV MULTI DROP DRIVER
                      </h2>
                      <div className="product-img-location">
                        <ul>
                          <li>
                            <i className="flaticon-pin" /> Hastings Freight Head
                            Office, Sheffield
                          </li>
                        </ul>
                      </div>
                      <br />
                      <p>
                        We're recruiting a daytime multi drop driver to work
                        within our local area. If you think this is you, please
                        email your CV to info@hastingsfreight.co.uk
                      </p>
                    </div>
                    <div className="product-info-bottom">
                      <div className="product-price">
                        <span>
                          £13.28 to £13.78 <label> p.h plus bonus</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    );
  }
}

export default AboutUsYourCareerContent;
